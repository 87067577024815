import { AxiosRequestConfig } from "axios";

export const getQRReferenceConfig = (qrReference: string): AxiosRequestConfig => ({
  method: "get",
  url: `/qrcode/reference/validate/${qrReference}`,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});
