"use client";
import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import PickerControlledItem from "./PickerControlledItem";
import { Tooltip } from "../Tooltip";
import { PickerControlledOption, PickerControlledProps } from "./types";
import { ConnectForm } from "@/utils/formHelper";
import "./styles.scss";

export function PickerControlled({
  title,
  options,
  multiple,
  id,
  dataQA,
  defaultValue,
  className,
  selector,
  onClick,
  name,
}: PickerControlledProps & { name: string }) {
  const { control } = useFormContext();

  const initialDefault =
    defaultValue || (multiple ? [] : typeof options[0] === "string" ? options[0] : options[0].item);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={initialDefault}
      render={({ field: { value, onChange } }) => {
        const [localSelectedOption, setLocalSelectedOption] = useState(value);

        useEffect(() => {
          setLocalSelectedOption(value);
        }, [value]);

        const handleSelectOption = (option: string) => {
          let newValue: string | string[];
          if (multiple) {
            newValue = Array.isArray(localSelectedOption)
              ? localSelectedOption.includes(option)
                ? localSelectedOption.filter((v) => v !== option)
                : [...localSelectedOption, option]
              : [option];
          } else {
            newValue = option;
          }
          setLocalSelectedOption(newValue);
          onChange(newValue);
          if (onClick) onClick(newValue);
        };

        return (
          <ConnectForm>
            {title && <p>{title}</p>}
            <ul
              data-testid="PickerControlled"
              data-qa={dataQA}
              className={`PickerControlled${className ? " " + className : ""}`}
              id={id}>
              {options.map((option: string | PickerControlledOption, i: number) => {
                const formattedOption = typeof option === "string" ? option : option.item;
                if (typeof option !== "string" && option.tooltip) {
                  return (
                    <React.Fragment key={i}>
                      <div>
                        <Tooltip
                          text={option.tooltip?.text}
                          position={option.tooltip?.position}
                          id={`${i}`}>
                          <PickerControlledItem
                            option={formattedOption}
                            label={option?.label}
                            id={i}
                            multiple={multiple}
                            selectedOption={localSelectedOption}
                            setSelectedOption={handleSelectOption}
                            selector={selector}
                          />
                        </Tooltip>
                      </div>
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={i}>
                    <PickerControlledItem
                      option={formattedOption}
                      label={typeof option !== "string" ? option?.label : undefined}
                      id={i}
                      multiple={multiple}
                      selectedOption={defaultValue}
                      setSelectedOption={handleSelectOption}
                      selector={selector}
                    />
                  </React.Fragment>
                );
              })}
            </ul>
          </ConnectForm>
        );
      }}
    />
  );
}
