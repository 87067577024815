import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

import { Input } from "@/components/Input";

import { ConnectForm } from "@/utils/formHelper";

import { FormValidationMethods } from "@/components/Form/types";
import { QREditorContext } from "@/contexts/QREditorContext";

import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import { TrackingInputsProps } from "./types";
import { Tracking } from "@/types/qr";

const analyticsId = {
  googleAnalytics: "ga",
  facebookPixel: "mp",
  GTM: "gtm",
};

export function TrackingInputs(props: TrackingInputsProps) {
  const { inputs } = props;

  const { t } = useTranslation("qr-editor-stats");
  const methods: FormValidationMethods = useFormContext();
  const { qrData, setQrData } = useContext<QREditorContextProps<any>>(QREditorContext);

  const [trackingInputs, setTrackingInputs] = useState<Tracking>(
    qrData?.data?.tracking || {
      ga: undefined,
      mp: undefined,
      gtm: undefined,
    }
  );

  const onChange = (id: string, value: string) => {
    const changedId = analyticsId[id];

    if (!changedId) return;

    setTrackingInputs((prev) => ({
      ...prev,
      [changedId]: value || undefined,
    }));
  };

  useEffect(() => {
    const newData = {
      ...qrData.data,
      tracking: trackingInputs,
    };

    Object.keys(analyticsId).forEach((key) => {
      if (newData[key] !== undefined) {
        delete newData[key];
      }
    });

    setQrData({
      ...qrData,
      data: newData,
    });
  }, [trackingInputs]);

  return (
    <ConnectForm>
      <>
        {inputs?.map((input, idx) => {
          const { label, placeholder, className, id, data_qa, errorMessage } = input;

          return (
            <div
              key={data_qa}
              className="u-spacing-4">
              <Controller
                control={methods?.control}
                name={id}
                render={() => (
                  <Input
                    id={idx.toString()}
                    type="text"
                    name={id}
                    label={t(label)}
                    placeholder={t(placeholder)}
                    defaultValue={trackingInputs[analyticsId[id]]}
                    dataQA={data_qa}
                    error={methods?.formState?.errors[id]}
                    errorMessage={methods?.formState?.errors[id]?.message}
                    className={className}
                    onChange={(e) => onChange(id, e)}
                  />
                )}
                rules={{
                  pattern: {
                    value: RegExp(errorMessage[0].rule),
                    message: t(errorMessage[0].message),
                  },
                }}
              />
            </div>
          );
        })}
      </>
    </ConnectForm>
  );
}
