export interface QRFRameProps {
  frameType: FrameType;
  text?: string;
  frameColor?: string;
  frameBackgroundColor?: string;
  textColor?: string;
  children?: JSX.Element;
  urlQr?: string;
}

export interface QRFRameToPrintProps {
  text: string;
  qrSvg: string;
  frameColor: string;
  frameBackgroundColor: string;
  textColor: string;
  width: number;
  height: number;
}

export enum FrameStyle {
  none = "none",
  mobile = "mobile",
  black = "black",
  white = "white",
  button = "button",
  ticket = "ticket",
  cafe = "cafe",
  shopping = "shopping",
  board = "board",
  envelope = "envelope",
  receipt = "receipt",
  eyelashes=  "eyelashes",
  hairsalon="hairsalon",
  beauty= "beauty",
  care= "care",
  waiter="waiter",
  bucket="bucket",
  takeaway= "takeaway",
  cup="cup",
  brochure="brochure",
  asian="asian",
  soda="soda",
  menu= "menu",
  restaurant= "restaurant",
  wine="wine",
  headphones="headphones",
  mic="mic",
  concert="concert",
  videogame="videogame",
  movie="movie",
  music= "music",
  mp3="mp3",
  film="film",
  hand="hand",
  like="like",
  chat="chat",
  stars="stars",
  review="review",
  ticket2="ticket2",
  wedding="wedding",
  christmas="christmas",
  party="party",
  tickets="tickets",
  celebration="celebration",
  partyhat="partyhat",
  balloons="balloons",
  garland="garland",
  bouquet="bouquet",
  giftbox="giftbox",
  cards="cards",
  shoppingbag="shoppingbag",
  cart="cart"
}


export type FrameType =
  | "none"
  | "mobile"
  | "black"
  | "white"
  | "button"
  | "ticket"
  | "museum"
  | "cafe"
  | "shopping"
  | "board"
  | "envelope"
  | "receipt"
  | "eyelashes"
  | "hairsalon"
  | "beauty"
  | "care"
  | "waiter"
  | "bucket"
  | "takeaway"
  | "cup"
  | "brochure"
  | "asian"
  | "soda"
  | "menu"
  | "restaurant"
  | "wine"
  | "headphones"
  | "mic"
  | "concert"
  | "videogame"
  | "movie"
  | "music"
  | "mp3"
  | "film"
  | "hand"
  | "like"
  | "chat"
  | "stars"
  | "review"
  | "ticket2"
  | "wedding"
  | "christmas"
  | "party"
  | "tickets"
  | "celebration"
  | "partyhat"
  | "balloons"
  | "garland"
  | "bouquet"
  | "giftbox"
  | "cards"
  | "shoppingbag"
  | "cart"

export interface QRFRameProps {
  frameType: FrameType;
  text?: string;
  frameColor?: string;
  frameBackgroundColor?: string;
  textColor?: string;
  children?: JSX.Element;
  urlQr?: string;
}

export interface QRFRameToPrintProps {
  text: string;
  qrSvg: string;
  frameColor: string;
  frameBackgroundColor: string;
  textColor: string;
  width: number;
  height: number;
}

export interface QRFrameStyle {
  frameStyle: FrameType;
  frameColor: string;
  frameBackgroundColor: string;
  frameTextColor: string;
  frameText: string;
}
