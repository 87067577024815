"use client";

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { ListInputHeader, ListActionButton, ListInputItems } from ".";

import { ConnectForm } from "@/utils/formHelper";

import { ListInputProps, ListInputSchema } from "./types";
import { FormValidationMethods } from "../Form/types";
import { QRFeedbackCategory } from "@/types/qr";
import { v4 as uuidv4 } from "uuid";

import "./styles.scss";

const buildNewInput = (label: string, placeholder: string, idAsUUID?: boolean): ListInputSchema => {
  const uniqueId = idAsUUID ? uuidv4() : Math.random().toString(36).substring(7);

  return {
    id: uniqueId,
    label,
    placeholder,
    rating: null,
  };
};

export const ListInput = (props: ListInputProps) => {
  const { t } = useTranslation("feedback");
  const methods: FormValidationMethods = useFormContext();

  const initialInputs = props.inputs || [
    buildNewInput(t("_feedbackCategoryInputTitle"), t("_feedbackCategoryInputPlaceholder"), true),
  ];

  const {
    dataQA,
    header,
    className,
    actionButton,
    inputs = initialInputs,
    id,
    defaultValue,
    componentRoot = "div",
    onClick,
  } = props;

  const [listOfInputs, setListOfInputs] = React.useState<ListInputSchema[]>(inputs);

  const classnames = classNames("list-input", {
    [`${className}`]: className,
  });

  const Component = componentRoot;

  const inputLabel = `${t("_feedbackCategoryInputTitleNoNumbered")} ${listOfInputs?.length + 1}`;
  const inputPlaceholder = t("_feedbackCategoryInputPlaceholder");

  const onAddInput = () => {
    const newInput = buildNewInput(inputLabel, inputPlaceholder, true);

    setListOfInputs([...listOfInputs, newInput]);
  };

  const onRemoveInput = (id: string) => {
    const updatedInputs = listOfInputs.filter((input) => input.id !== id);
    const reorderedLabels = updatedInputs.map((input, index) => {
      return {
        ...input,
        label: `${t("_feedbackCategoryInputTitleNoNumbered")} ${index + 1}`,
      };
    });

    setListOfInputs(reorderedLabels);
  };

  const onChangeInput = (id: string, value: string) => {
    const updatedInputs = listOfInputs.map((input) => {
      if (input.id === id) {
        return {
          ...input,
          value,
        };
      }

      return input;
    });

    setListOfInputs(updatedInputs);
  };

  React.useEffect(() => {
    onClick && onClick(listOfInputs);
  }, [listOfInputs]);

  React.useEffect(() => {
    if (defaultValue) {
      const initialListOfInputs: ListInputSchema[] = defaultValue.map(
        (category: QRFeedbackCategory, index: number) => {
          return {
            id: category.id,
            label: `${t("_feedbackCategoryInputTitleNoNumbered")} ${index + 1}`,
            placeholder: inputPlaceholder,
            value: category.name,
            rating: category.rating,
          };
        }
      );

      setListOfInputs(initialListOfInputs);
    }
  }, [defaultValue]);

  return (
    <ConnectForm>
      <Component
        id={id}
        data-qa={dataQA}
        className={classnames}>
        <ListInputHeader
          title={header?.title}
          subtitle={header?.subtitle}
        />
        <ListInputItems
          inputs={listOfInputs}
          methods={methods}
          onRemove={onRemoveInput}
          onChange={onChangeInput}
        />
        <ListActionButton
          actionButton={actionButton}
          onActionButtonClick={onAddInput}
          disabled={listOfInputs.length >= 10}
        />
      </Component>
    </ConnectForm>
  );
};
