import React from "react";

import { Alert } from "@/components/Alert";
import { Accordion } from "@/components/Accordion";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { Links } from "@/components/Links";
import { Select } from "@/components/Select";
import { Typography } from "@/components/Typography";
import { Link } from "@/components/Link";
import { ImageUpload } from "@/components/ImageUpload";
import { Checkbox } from "@/components/Checkbox";
import { Divider } from "@/components/Divider";
import { Picker } from "@/components/Picker";
import { ThemePicker } from "@/components/ThemePicker";
import { ListPicker } from "@/components/ListPicker";
import { FilesUpload } from "@/components/FilesUpload";
import { BusinessHours } from "@/components/BusinessHours";
import { ColorGroup } from "@/components/ColorGroup";
import { EventDateAndTime } from "@/components/EventDateTime";
import { CouponDateTime } from "../CouponDateTime";
import { DatePicker } from "../DatePicker";
import { TimezoneSelector } from "../TimezoneSelector";
import { Tabs } from "../Tabs";
import { FramesSelect, FramesTabs } from "@/containers/QREditor/FramesDesign";
import { ListInput } from "@/components/ListInput";
import { TemplatesTabs } from "@/containers/QREditor/TemplatesContainer/components/TemplatesTabs/TemplatesTabs";

import { TrackingInputs } from "@/containers/QREditor/CreateContainer/components/TrackingInputs";
import { SwitchWithInput } from "@/containers/QREditor/CreateContainer/components/SwitchWithInput";
import { PickerControlled } from "../PickerControlled/PickerControlled";
import { ColorGroupControlled } from "../ColorGroupControlled";
import { InputControlled } from "../InputControlled";

const DivComponent = ({ dataTestId, ...props }) => {
  return (
    <div
      data-testid={dataTestId}
      {...props}>
      {props.children}
    </div>
  );
};

const Components = {
  Accordion: Accordion,
  Alert: Alert,
  Button: Button,
  BusinessHours: BusinessHours,
  Checkbox: Checkbox,
  Typography: Typography,
  ThemePicker: ThemePicker,
  div: DivComponent,
  Divider: Divider,
  Input: Input,
  InputControlled: InputControlled,
  ImageUpload: ImageUpload,
  Picker: Picker,
  PickerControlled: PickerControlled,
  ListPicker: ListPicker,
  ListInput: ListInput,
  FilesUpload: FilesUpload,
  Links: Links,
  Link: Link,
  Select: Select,
  ColorGroup: ColorGroup,
  ColorGroupControlled: ColorGroupControlled,
  EventDateTime: EventDateAndTime,
  CouponDateTime: CouponDateTime,
  DatePicker: DatePicker,
  TimezoneSelector: TimezoneSelector,
  Tabs: Tabs,
  FramesTabs: FramesTabs,
  TemplatesTabs: TemplatesTabs,
  FramesSelect: FramesSelect,
  SwitchWithInput: SwitchWithInput,
  TrackingInputs: TrackingInputs,
};

export default Components;
