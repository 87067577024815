import React from "react";
import { ReactSVG } from "react-svg";
import { PickerControlledOptionProps } from "./types";
import "./styles.scss";

export default function PickerControlledItem({
  option,
  label,
  id,
  selectedOption,
  setSelectedOption,
  multiple,
  selector,
}: PickerControlledOptionProps) {
  const onSelectOption = (option: string) => {
    setSelectedOption(option);
  };

  const renderOption = () => {
    if (label) {
      return (
        <div className="PickerControlled__item__container">
          <ReactSVG
            className="PickerControlled__icon"
            src={`/svg/${selector}-${option}.svg`}
          />
          <span className="PickerControlled__label">{label}</span>
        </div>
      );
    }
    return (
      <ReactSVG
        className="PickerControlled__icon"
        src={`/svg/${selector}-${option}.svg`}
      />
    );
  };

  return (
    <li
      data-option={option}
      data-testid="PickerControlled-item"
      data-qa={`${option}-button`}
      className={`PickerControlled__item ${
        multiple
          ? Array.isArray(selectedOption) && selectedOption.includes(option)
            ? "PickerControlled__item--active"
            : ""
          : selectedOption === option
            ? "PickerControlled__item--active"
            : ""
      }`}
      onClick={() => onSelectOption(option)}>
      {selector && renderOption()}
    </li>
  );
}
