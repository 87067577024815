import React, { useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Switch } from "@/components/Switch";
import { Input } from "@/components/Input";
import { TemplateData } from "@/data/types/templates";
import { TemplateCard } from "../TemplateCard/TemplateCard";
import { QREditorContext } from "@/contexts/QREditorContext";
import { FormValidationMethods } from "@/components/Form/types";
import { TemplatesContentProps } from "./types";
import { sortTemplatesByCreatedAt } from "@/containers/Templates/utils";
import "./styles.scss";

export const TemplatesContent = ({ selectedIndex, userTemplates }: TemplatesContentProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const methods: FormValidationMethods = useFormContext();

  const [selectedTemplate, setSelectedTemplate] = React.useState<TemplateData | null>(null);
  const { setQrData, qrData, setCreateNewTemplate, createNewTemplate } =
    useContext(QREditorContext);
  const [inputNameValue, setInputNameValue] = React.useState("");

  useEffect(() => {
    if (selectedIndex === 1) {
      setCreateNewTemplate(false);
      handleTemplateNameChange(null);
      setInputNameValue("");
    }
    if (selectedIndex === 0) {
      setSelectedTemplate(null);
    }
  }, [selectedIndex]);

  const handleClick = (template: TemplateData) => {
    setSelectedTemplate(template);
    setQrData({ ...qrData, qrStyle: template.style });
  };

  const handleTemplateNameChange = (event: string) => {
    setInputNameValue(event);
    setQrData({ ...qrData, name: event });
  };

  const sortedTemplates = sortTemplatesByCreatedAt(userTemplates);

  const handleSwitchChange = () => {
    setCreateNewTemplate(!createNewTemplate);
  };

  const renderFirstTabContent = () => {
    return (
      <div className="switch_container">
        <Switch
          onToggleSwitch={handleSwitchChange}
          switchValue={createNewTemplate}
          label="Save template when finished"
          id="newTemplate"
        />
        {createNewTemplate && (
          <div>
            <Controller
              control={methods?.control}
              name="templateName"
              render={() => (
                <Input
                  id="templateName"
                  name="templateName"
                  type="text"
                  value={inputNameValue}
                  placeholder="e.g. New template"
                  label="Template Name"
                  onChange={handleTemplateNameChange}
                  error={!!errors.templateName}
                  errorMessage={errors.templateName?.message}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Required Field",
                },
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const renderSecondTabContent = () => {
    return (
      <div className="user-templates__list">
        {sortedTemplates.map((template: TemplateData) => (
          <TemplateCard
            onClick={() => handleClick(template)}
            key={template.id}
            templateData={template}
            selectedTemplate={selectedTemplate}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="templates-tabs-content-container">
      {selectedIndex === 0 && renderFirstTabContent()}
      {selectedIndex === 1 && renderSecondTabContent()}
    </div>
  );
};
