import React, { useEffect } from "react";
import classNames from "classnames";
import { FiChevronDown } from "react-icons/fi";
import { SelectProps } from "./types";
import { ConnectForm } from "@/utils/formHelper";
import { FrameDesignContent } from "../FrameDesignContent/FrameDesignContent";
import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import { QREditorContext } from "@/contexts/QREditorContext";
import { getFrameSectionClass, handleFrameSelectedSection } from "@/utils/framesHelper";
import "./styles.scss";

export const FramesSelect = ({
  id,
  dataQA,
  label,
  options,
  placeholder,
  error,
  success,
  disabled,
  name,
  className,
  prefix,
  onChange,
  defaultSelected,
  selectContent,
}: SelectProps) => {
  const classnames = classNames(`FramesSelect`, {
    [className]: className,
    "FramesSelect--error": error,
    "FramesSelect--success": success,
    "FramesSelect--disabled": disabled,
  });

  const { qrData } = React.useContext<QREditorContextProps<any>>(QREditorContext);

  const [value, setValue] = React.useState(defaultSelected);
  const [selectoptions] = React.useState([placeholder, ...options]);
  const [prefixWidth, setPrefixWidth] = React.useState(0);

  useEffect(() => {
    const isFrameSelected = handleFrameSelectedSection(
      selectContent,
      qrData,
      "frame-div-toggle-select"
    );
    isFrameSelected && setValue(isFrameSelected.content.selectParent);
  }, []);

  const prefixRef = React.useRef<HTMLDivElement>(null);

  const handleSections = (optionSelected) => {
    getFrameSectionClass("frame-div-toggle-select", optionSelected);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    var dropDown = document.getElementById(id) as HTMLSelectElement;
    const optionSelected = dropDown.options[dropDown.selectedIndex].id;
    optionSelected && handleSections(optionSelected);

    setValue(e.target?.value);
    if (onChange) {
      onChange(e.target?.value);
    }
  };

  React.useEffect(() => {
    if (prefixRef.current) {
      setPrefixWidth(prefixRef.current.getBoundingClientRect().width);
    }
  }, [prefix]);

  return (
    <div id="frames-select">
      <div
        data-testid="Select"
        data-qa={dataQA}
        className={classnames}>
        <div className="Input__label-container">
          {label && (
            <label
              data-testid="Select__label"
              htmlFor={id}
              className="Select__label">
              {label}
            </label>
          )}
        </div>

        <div className="Select__wrapper">
          {prefix && (
            <div
              ref={prefixRef}
              className="Select__prefix">
              {prefix}
            </div>
          )}
          <ConnectForm>
            <select
              data-testid="Select__element"
              id={id}
              disabled={disabled}
              name={name}
              value={value}
              required
              onChange={handleOnChange}
              style={{ paddingLeft: prefixWidth ? `${prefixWidth + 5}px` : "" }}>
              {selectoptions?.map((item, id) => (
                <option
                  data-testid="Select__option"
                  className={placeholder && id === 0 ? "Select__element--placeholder" : ""}
                  key={id}
                  value={id === 0 ? "" : item}
                  data-placeholder={!!(id === 0)}
                  id={id.toString()}>
                  {item}
                </option>
              ))}
            </select>
          </ConnectForm>
          <FiChevronDown />
        </div>
      </div>

      <div className="select-content">
        <FrameDesignContent
          content={selectContent}
          className={"frame-div-toggle-select"}
        />
      </div>
    </div>
  );
};
