import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Input } from "@/components/Input";

import { ListInputItemsProps } from "./types";

export const ListInputItems = ({ inputs, methods, onRemove, onChange }: ListInputItemsProps) => {
  const { t } = useTranslation("feedback");

  return (
    <div data-testid="list-input-items">
      {inputs?.map((input, idx) => {
        const { 
          label, 
          placeholder, 
          className, 
          id, 
          data_qa,
          value
        } = input;

        const classnames = classNames("list-input__item", {
          [`${className}`]: className,
        });
        
        return (
          <div key={id} className={classnames}>
            <Controller 
              control={methods?.control}
              name={id}
              render={() => (
                <>         
                  <Input
                    id={idx.toString()}
                    type="text"
                    name={id}
                    label={label}
                    placeholder={placeholder}
                    defaultValue={value}
                    dataQA={data_qa}
                    error={methods?.formState?.errors[id]}
                    errorMessage={methods?.formState?.errors[id]?.message}
                    className={inputs?.length > 1 ? "remove-button" : ""}
                    onChange={(e) => onChange(id, e)}
                  />
                  {
                    inputs?.length > 1 && (
                      <div
                        data-testid="ListPickerRemoveButton"
                        className="ListPicker__icon ListPicker__remove"
                        onClick={() => onRemove(id)}
                      >
                        <div>
                          <span></span>
                        </div>
                      </div>
                    )
                  }
                </>
              )}
              rules={{
                required: {
                  value: true,
                  message: t("_feedbackRequiredCategory"),
                },
                maxLength: {
                  value: 200,
                  message: t("_feedbackCategoryNameMaxLength"),
                },
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
