import { AxiosRequestConfig } from "axios";
import { Token } from "@/data/types/templates";

export const getTemplatesConfig = (
  authorization: string,
  queryParamsString?: string
): AxiosRequestConfig => {
  return {
    method: "GET",
    url: `/me/template${queryParamsString ? `?${queryParamsString}` : ""}`,
    headers: {
      Accept: "*/*",
      Authorization: `Bearer ${authorization}`,
      "Content-Type": "application/json",
    },
  };
};
