import client from "../client";
import { getTemplatesConfig } from "./getTemplatesConfig";
import { handleAxiosError } from "@/utils/apiErrorHandler";
import { Token } from "@/data/types/templates";
import { GetTemplatesResponse } from "./types";

/**
 * Fetches all templates.
 *
 * @param {Token} token - The authentication token.
 * @returns {Promise<GetTemplatesResponse>} - A promise that resolves with the templates data.
 */
export const getTemplatesService = async ({
  authorization,
  queryParamsString,
}: {
  authorization: string;
  queryParamsString?: string;
}): Promise<GetTemplatesResponse> => {
  if (!authorization) {
    throw new Error("Authentication token is required for fetching templates.");
  }

  try {
    const response = await client(getTemplatesConfig(authorization, queryParamsString));
    return response as GetTemplatesResponse;
  } catch (error: any) {
    handleAxiosError(error);
  }
};
