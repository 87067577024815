
import { getSiteUrl } from "@/utils/site";
import { TemplateCardProps } from "./types";
import { Tooltip } from "@/components/Tooltip";
import { FrameStyle, FrameType } from "@/components/QRFrame/types";
import { getFramedQr } from "@/utils/qrEditorHelper";
import { useEffect, useState } from "react";
import { isClient, isSafari } from "@/utils/browser";
import { isFramedQr } from "@/utils/dashboard";
import { useQRStyle } from "@/hooks/qr/useQR";
import "./styles.scss";

export const TemplateCard = ({ templateData, onClick, selectedTemplate }: TemplateCardProps) => {
  const { id, name, style } = templateData;
  const { ref, updateQRCode, qrCode, getRawData } = useQRStyle();
  const [urlQr, setUrlQr] = useState("");

  useEffect(() => {
    updateQRCode({
      qrStyle: { ...templateData?.style, styleType: "STANDARD" },
      qrUrl: getSiteUrl(),
      margin: 0,
    });
  }, [qrCode]);

  useEffect(() => {
    if (isClient() && isSafari() && qrCode) {
      setTimeout(() => {
        getRawData().then((data) => {
          if (data) {
            setUrlQr(URL.createObjectURL(data));
          }
        });
      }, 10);
    }
  }, [qrCode, templateData?.style]);

  const getFramedQrCodeComponent = () => {
    const frameStyleClass =
      style?.frameStyle !== FrameStyle.button ? style?.frameStyle : "buttonFrame";
    return (
      <div
        className={`templates--editor__card__qr templates--editor__card__qr--framed ${frameStyleClass}`}>
        {getFramedQr(style?.frameStyle as FrameType, ref, templateData, urlQr)}
      </div>
    );
  };

  const getNotFramedQrCodeComponent = () => {
    return (
      <div
        className="templates--editor__card__qr no-frame"
        ref={ref}
      />
    );
  };

  return (
    <Tooltip
      text={name}
      position={"top"}
      id={id}>
      <div
        className={`templates--editor__card__body ${id === selectedTemplate?.id ? "selected" : ""}`}
        onClick={() => onClick(templateData)}
        id={id}>
        <div className="templates--editor__card__image-wrapper">
          {!isFramedQr(false, templateData?.style)
            ? getNotFramedQrCodeComponent()
            : getFramedQrCodeComponent()}
        </div>
      </div>
    </Tooltip>
  );
};
