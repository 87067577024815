"use client";
import React, { useState, useEffect, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FiAlertCircle } from "react-icons/fi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import classNames from "classnames";

import { InputProps } from "./types";
import { InputTooltipHelper } from "@/utils/inputTooltipHelper";
import { isClient } from "@/utils/browser";
import { ColorPicker } from "../ColorPicker";

import { QREditorContext } from "@/contexts/QREditorContext";
import "./styles.scss";

export const InputControlled = ({
  id,
  dataQA,
  label,
  type,
  placeholder,
  errorMessage,
  error,
  success,
  customTag,
  disabled,
  defaultValue,
  className,
  title,
  helper,
  name,
  colorPicker,
  colorPickerWithDebounce = false,
  noMb,
  onChange,
  onFocus,
  onKeyDown,
}: InputProps) => {
  const { control } = useFormContext();
  const classnames = classNames("Input", {
    [`Input--${className}`]: className,
    "Input--error": error,
    "Input--success": type !== "password" && success,
    "Input--disabled": disabled,
    "Input--colorPicker": colorPicker !== undefined,
    "Input--noMb": noMb,
  });

  const [color, setColor] = useState(defaultValue || colorPicker);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const Tag: any = customTag ? customTag : "input";
  const tooltipHelper = helper && typeof helper !== "string";
  const qrEditorContext = useContext(QREditorContext);

  const qrStyleFieldValue = qrEditorContext?.qrData?.qrStyle?.[name];
  const setQRData = qrEditorContext?.setQrData;

  const [inputValue, setInputValue] = useState<string | undefined>(
    qrStyleFieldValue || defaultValue
  );

  useEffect(() => {
    setInputValue(qrStyleFieldValue);
    onChange && onChange(qrStyleFieldValue);
    setColor(qrStyleFieldValue);
  }, [qrStyleFieldValue]);

  useEffect(() => {
    setColor(defaultValue);
  }, [defaultValue]);

  const onHandleColorChange = (newColor: string, fieldOnChange: (value: any) => void) => {
    setQRData({
      ...qrEditorContext.qrData,
      qrStyle: { ...qrEditorContext.qrData.qrStyle, [name]: newColor },
    });
    setColor(newColor);
    fieldOnChange(newColor);
    setInputValue(qrStyleFieldValue);
    onChange && onChange(qrStyleFieldValue);
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={inputValue}
      render={({ field }) => (
        <div
          data-testid="Input"
          data-qa={dataQA}
          className={classnames}>
          <div className="Input__label-container">
            {label && (
              <label
                data-testid="Input__label"
                htmlFor={id}
                className="Input__label">
                {label}
              </label>
            )}
            {tooltipHelper && InputTooltipHelper({ helper, id })}
          </div>
          <div className="Input__wrapper">
            <Tag
              data-testid="Input__element"
              className="Input__element"
              id={id}
              title={title}
              type={passwordVisible ? "text" : type}
              placeholder={placeholder}
              disabled={disabled}
              name={name}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
              onBlur={field.onBlur}
              autoComplete="off"
              value={inputValue}
              onFocus={(e) => onFocus && onFocus(e)}
              onKeyDown={(e) => onKeyDown && onKeyDown(e)}
            />
            {type === "password" && (
              <span
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="Input__passwordToggle"
                data-testid="Input__passwordToggle">
                {passwordVisible ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            )}
            {colorPicker !== undefined && (
              <ColorPicker
                id={id}
                color={color}
                withDebounce={colorPickerWithDebounce}
                onChange={(newColor) => onHandleColorChange(newColor, field.onChange)}
              />
            )}
          </div>
          {error && (
            <p
              data-testid="Input__error-message"
              className="Input__error-message body-s">
              <FiAlertCircle />
              {errorMessage as string}
            </p>
          )}
          {helper && !tooltipHelper && <span className="Input__helper">{helper as string}</span>}
        </div>
      )}
    />
  );
};
