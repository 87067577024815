import React from "react";
import { set } from "lodash";

import { useTranslation } from "react-i18next";
import { DatePicker } from "../DatePicker";
import { TimezoneSelector } from "../TimezoneSelector";

import { CouponDateTimeType } from "@/types/qr";

import { PAGES } from "@/internals/constants/pages";

import "./styles.scss";

//TODO Coupon Review this component and replace with DatePicker and TimezoneSelector components directly
export function CouponDateTime({
  defaultValue,
  onClick,
}: {
  defaultValue: CouponDateTimeType;
  onClick?: (arg: CouponDateTimeType) => void;
}) {
  const { t: translate } = useTranslation(PAGES.QR_COUPON);

  const [dateTime, setDateTime] = React.useState<CouponDateTimeType>(defaultValue);

  const onInputChangeHandler = ({ name, value }) => {
    const newDateTime = { ...dateTime, [name]: value };
    setDateTime(newDateTime);

    if (Array.isArray(name)) {
      const newObject = set({}, name, value);
      return onClick && onClick(newObject as any);
    }

    return onClick && onClick(newDateTime);
  };

  return (
    <div className="coupon-date-time">
      <DatePicker
        type="single"
        label={translate("_couponTimeEndLabel")}
        inputPlaceholder={translate("_couponTimeEndPlaceholder")}
        defaultDate={defaultValue?.couponValidDate ? [new Date(defaultValue?.couponValidDate)] : []}
        fluid
        disableMobile
        className={"coupon-date-time__date-picker"}
        onChangeDate={([date] = []) =>
          onInputChangeHandler({ name: "couponValidDate", value: date })
        }
      />
      <TimezoneSelector
        placeholder={translate("_couponTimezonePlaceholder")}
        label={translate("_couponTimezoneLabel")}
        value={defaultValue?.couponTimezone}
        onChange={(timezone) => onInputChangeHandler({ name: "couponTimezone", value: timezone })}
      />
    </div>
  );
}
