import React from "react";
import { map, isArray, filter } from "lodash";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { FiAlertCircle } from "react-icons/fi";
import classNames from "classnames";

import { Typography } from "../Typography";
import { AddedFileItem } from "./AddedFileItem";

import { QREditorContext } from "@/contexts/QREditorContext";
import { LoadingBarContext } from "@/contexts/LoadingBarContext";
import { ModalContext } from "@/contexts/ModalContext";
import { ConnectForm } from "@/utils/formHelper";
import {
  AUDIO_TYPE,
  IMAGE_TYPE,
  PDF_TYPE,
  VIDEO_TYPE,
  fileExceedSize,
  fileToBase64,
  invalidFileType,
} from "@/utils/uploadHelper";
import { getImageCropperModal } from "@/modals/ImageCropperModal";
import { convertStringAspectRatioToNumber } from "../ImageCropper/utils";

import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import { FormValidationMethods } from "../Form/types";
import { PAGES } from "@/data/types/pages";
import { FileProps, FilesUploadProps } from "./types";

import "./styles.scss";

export const FilesUpload = (props: FilesUploadProps) => {
  const qrEditorContext = React.useContext<QREditorContextProps<any>>(QREditorContext);
  const fileUploaderContext = React.useContext(LoadingBarContext);

  const methods: FormValidationMethods = useFormContext();
  const modalContext = React.useContext(ModalContext);

  const { t } = useTranslation(PAGES.QR_IMAGE);
  const {
    label,
    buttonLabel,
    minMaxFileLabel,
    minMaxFileValue,
    id,
    required,
    defaultValue,
    maxFiles,
    showEditor,
    maximumSizeLabel,
    onClick,
    ctaStyle,
  } = props;
  const KILOBYTE_SIZE = 1024;

  const [isDropZoneDragged, setIsDropZoneDragged] = React.useState(false);
  const [files, setFiles] = React.useState<FileProps[]>(defaultValue || []);
  const [fileURLs, setFileURLs] = React.useState([]);
  const [fileSizeTypeError, setFileSizeTypeError] = React.useState(undefined);

  const onSetFiles = async (e) => {
    methods?.clearErrors();
    const files = e.target.files;
    const promises = [];

    map(files, (file) => {
      const validateFileType = invalidFileType(props.type, file);
      const validateFileSize = fileExceedSize(file);

      if (validateFileType) {
        setFileSizeTypeError(t(validateFileType.errorMessage));
      } else {
        if (validateFileSize) {
          setFileSizeTypeError(t(validateFileSize.errorMessage));
        } else {
          methods?.clearErrors();
          const reader = new FileReader();
          const promise = new Promise<void>((resolve) => {
            reader.onload = () => {
              const fileBlob = new Blob([reader.result], {
                type: props.type === "pdf" ? PDF_TYPE : file.type,
              });

              const fileURL = URL.createObjectURL(fileBlob);

              setFiles((files) => [
                ...files,
                {
                  name: file.name,
                  size: file.size,
                  url: fileURL,
                  type: file.type,
                },
              ]);
              resolve();
            };
          });

          reader.readAsArrayBuffer(file);
          promises.push(promise);
          setFileSizeTypeError(undefined);
        }
      }
    });

    await Promise.all(promises);
    setIsDropZoneDragged(false);
    e.target.value = "";
  };

  const onChangeFiles = async (e) => {
    if (props.type === "image" && showEditor) {
      const file = e.target.files[0];
      const fileToBlob = await fileToBase64(file);

      modalContext.handleModal({
        content: getImageCropperModal({
          data: {
            image: fileToBlob,
            getFile: true,
            fileType: file.type,
            aspectRatio:
              showEditor === "profile" ? "profile" : convertStringAspectRatioToNumber(showEditor),
            ctaStyle,
          },
          onConfirmButton: async (blob: Blob) => {
            const newFile = new File([blob], file.name, { type: file.type });
            await onSetFiles({ target: { files: [newFile] } });
            modalContext.handleModal();
          },
        }),
        modalProps: {
          className: "u-modal u-modal--big u-box u-box u-box--small u-text-center",
        },
      });
    } else {
      await onSetFiles(e);
    }
  };

  const removeFile = (index: number) => {
    setFiles((files) => (isArray(files) ? filter(files, (_, idx) => idx !== index) : []));
    setFileSizeTypeError(undefined);
  };

  const getImageSize = (idx: number) => {
    const fileSize = Number(files[idx]?.size);

    if (fileSize === 0 || isNaN(fileSize)) return <Typography Tag={"p"}>&nbsp;</Typography>;

    return (
      <Typography Tag={"p"}>
        {`${t("_imageSize")}: `}
        {files[idx]?.size
          ? (Number(files[idx]?.size) / KILOBYTE_SIZE).toFixed(2)
          : Number(Number(qrEditorContext?.qrData?.data.size) / KILOBYTE_SIZE).toFixed(2)}
        KB
      </Typography>
    );
  };

  React.useEffect(() => {
    if (files.length < 1) setFileURLs([]);
    setFileURLs(isArray(files) ? map(files, (fil) => fil) : [files]);
    onClick && onClick(files);
  }, [files]);

  React.useEffect(() => {
    if (fileURLs.length > 0) {
      qrEditorContext?.setValidationError(false);
      methods?.clearErrors("emptyImagesGallery");
    }
  }, [fileURLs]);

  React.useEffect(() => {
    if (methods?.formState.isSubmitting && required) {
      if (fileURLs.length === 0) {
        qrEditorContext?.setValidationError(true);
        methods?.setError("emptyImagesGallery", {
          type: "required",
          message: t("_emptyImagesGallery"),
        });
      } else {
        qrEditorContext?.setValidationError(false);
        methods?.clearErrors("emptyImagesGallery");
      }
      setFileSizeTypeError(undefined);
    }
  }, [methods?.formState]);

  React.useEffect(() => {
    // En el caso de los vídeos, está conectado con el input de url, por lo que reaccionamos al array de videos para actualizar la lista
    if (qrEditorContext?.qrData?.data?.videoUrls) {
      setFiles(qrEditorContext?.qrData?.data?.videoUrls);
    }
  }, [qrEditorContext?.qrData?.data]);

  const showUploader = (props.type === "pdf" && files.length < 1) || props.type !== "pdf";

  const displayErrorMessage =
    methods?.formState.errors["emptyImagesGallery"] ||
    methods?.formState.errors["maxSizeExceeded"] ||
    fileSizeTypeError;

  const isErrorMessage =
    methods?.formState.errors["emptyImagesGallery"]?.message ||
    methods?.formState.errors["maxSizeExceeded"]?.message ||
    fileSizeTypeError;

  const anyFileIsLoading = fileUploaderContext?.isLoading.some((isLoading) => isLoading);
  const maxFilesReached = maxFiles !== undefined && fileURLs.length >= Number(maxFiles);
  const dropZoneClasses = classNames("FilesUpload__drop-zone", {
    "FilesUpload__drop-zone--disabled": maxFilesReached || anyFileIsLoading,
  });

  const getAcceptedFileTypes = () => {
    if (props.type === "pdf") return PDF_TYPE;
    if (props.type === "audio") return AUDIO_TYPE;
    if (props.type === "video") return VIDEO_TYPE;
    return IMAGE_TYPE;
  };

  const getFileType = (file: FileProps) => {
    if (props.type === "audio") return "audio";
    if (file?.type?.includes("video") || file?.type?.includes("audio")) {
      return "multimedia";
    }
    return props.type === "pdf" ? props.type : "image";
  };

  return (
    <ConnectForm>
      <div
        className="FilesUpload"
        data-qa={props.dataQA}>
        <Typography Tag="p">{label}</Typography>
        {showUploader && (
          <div className={dropZoneClasses}>
            <label
              htmlFor={id || "file"}
              className={`FilesUpload__drop-zone__wrapper${
                isDropZoneDragged ? " drag-active" : ""
              }`}
              onDragOver={() => setIsDropZoneDragged(true)}
              onDragLeave={() => setIsDropZoneDragged(false)}>
              <input
                type="file"
                name="file"
                data-testid="FilesUpload-input"
                id={id || "file"}
                accept={getAcceptedFileTypes()}
                className="FilesUpload__drop-zone__input"
                onChange={async (e) => {
                  await onChangeFiles(e);
                }}
                multiple
              />
              <div className={`FilesUpload__drop-zone__button`}>
                <label
                  htmlFor={id || "file"}
                  className={`${ctaStyle ? "button--rounded" : ""}`}
                  onChange={async (e) => await onChangeFiles(e)}>
                  {buttonLabel}
                </label>
                <Typography Tag={"p"}>
                  {maximumSizeLabel || t(minMaxFileLabel)}
                  {`${minMaxFileValue}` || "15 MB"}
                </Typography>
              </div>
            </label>
          </div>
        )}
        {displayErrorMessage && (
          <p className="FilesUpload__error">
            <FiAlertCircle />
            {isErrorMessage}
          </p>
        )}
        <div className="FilesUpload__files">
          {fileURLs.length > 0 &&
            map(isArray(fileURLs) ? fileURLs : [fileURLs[0]], (fileURL, idx) => {
              const isMultimedia =
                fileURL?.type?.includes("video") || fileURL?.type?.includes("audio");
              const fileType = getFileType(fileURL);
              const fileName =
                files[idx]?.name ||
                qrEditorContext?.qrData?.data.pdfFileName ||
                qrEditorContext?.qrData?.data.mp3FileName;
              const fileString = typeof fileURL === "string";

              return (
                <AddedFileItem
                  key={idx}
                  fileId={idx}
                  fileUrl={fileString ? fileURL : fileURL?.url}
                  fileName={fileName}
                  fileSize={getImageSize(idx)}
                  onRemoveItem={() => removeFile(idx)}
                  loading={fileUploaderContext?.isLoading[idx]}
                  disabled={anyFileIsLoading}
                  fileType={fileType}
                  ctaStyle={ctaStyle}
                />
              );
            })}
        </div>
      </div>
    </ConnectForm>
  );
};
