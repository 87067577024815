import React, { useEffect } from "react";

import { FrameDesignContentProps } from "./types";
import { FramePicker } from "../FramesPicker/FramePicker";
import { QREditorContext } from "@/contexts/QREditorContext";
import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import {
  displayFrameDesign,
  validateFrameColor,
  validateFrameTextColor,
} from "@/utils/framesHelper";

export const FrameDesignContent = ({ content, className }: FrameDesignContentProps) => {
  const { qrData, setQrData } = React.useContext<QREditorContextProps<any>>(QREditorContext);
  const [selectedFrameStyle, setSelectedFrameStyle] = React.useState<any>(
    qrData.qrStyle.frameStyle || "none"
  );
  useEffect(() => {
    setSelectedFrameStyle(qrData.qrStyle.frameStyle);
    displayFrameDesign(qrData.qrStyle.frameStyle);
  }, []);

  useEffect(() => {
    displayFrameDesign(selectedFrameStyle);

    const frameTextColor = qrData?.qrStyle?.frameTextColor?.toLowerCase();
    const frameColor = qrData?.qrStyle?.frameColor?.toLowerCase();

    setQrData({
      ...qrData,
      qrStyle: {
        ...qrData.qrStyle,
        frameStyle: selectedFrameStyle,
        frameTextColor: validateFrameTextColor({
          frameColor,
          frameTextColor,
          frameStyle: selectedFrameStyle,
        }),
        frameColor: validateFrameColor({
          frameColor,
          frameStyle: selectedFrameStyle,
        }),
      },
    });
  }, [selectedFrameStyle]);

  return (
    <div className="frames-tabs-content-container ">
      {content.map((item, i) => (
        <section
          id={`section-${item.id}`}
          className={className}>
          {item.content.type === "Picker" && (
            <FramePicker
              options={item.content.options}
              id={item.content.pickerId}
              selector={item.content.selector}
              className={item.content.className}
              onClick={setSelectedFrameStyle}
              defaultSelected={selectedFrameStyle}
            />
          )}
        </section>
      ))}
    </div>
  );
};
