import { ListInputHeaderProps } from "./types";

export const ListInputHeader = ({ title, subtitle }: ListInputHeaderProps ) => {
  if (!title) {
    return null;
  }

  return (
    <div data-testid="list-input-header">
      {title}
      {subtitle}
    </div>
  );
}
