import React, { useContext } from "react";
import { SwitchWithInputContext } from "@/containers/QREditor/CreateContainer/components/SwitchWithInput/SwitchWithInput";
import { SwithProps } from "./types";

import "./styles.scss";

export const Switch = ({ label, content, name, id, onToggleSwitch, switchValue }: SwithProps) => {
  const Context = useContext(SwitchWithInputContext);

  const toggleSwitch = Context?.toggleSwitch;
  const switchVal = Context?.switchVal;

  const onToggleSwitchHandle = () => {
    Context ? toggleSwitch() : onToggleSwitch();
  };

  return (
    <div
      className="switch"
      data-testid="switch">
      <label
        className="switch__label"
        htmlFor="switch__toggle"
        data-testid="switch__label">
        <input
          className="switch__toggle"
          id={`switch__toggle ${id}`}
          type="checkbox"
          onClick={onToggleSwitchHandle}
          checked={switchValue || switchVal}
          name={name}
          defaultChecked={switchValue || switchVal}
          data-val="true"
          value={"true"}
        />
        <span className="switch__span">{label}</span>
      </label>
      {content && (!switchVal || !switchValue) && <div className="swicth__content">{content}</div>}
    </div>
  );
};
