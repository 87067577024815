import React, { useMemo } from "react";

import { HiOutlinePlus } from "react-icons/hi";
import { FiTrash2 } from "react-icons/fi";
import { clone, cloneDeep, filter, startCase } from "lodash";
import { useTranslation } from "react-i18next";

import { Button } from "../Button";
import { getTimerModal } from "@/modals/TimerModal";
import { Checkbox } from "../Checkbox";
import { Divider } from "../Divider";

import { BusinessHourInput } from "./BusinessHourInput";

import { ModalContext } from "@/contexts/ModalContext";
import { getEmptyDays } from "@/utils/qrEditorHelper";

import {
  formatToBusinessHoursInput,
  formatToGenericTime,
  formatToTimerTime,
} from "@/utils/timerHelper";

import { BusinessHourProps, BusinessHourTime, BusinessHoursDay, BusinessHoursDays } from "./types";
import { TimeFormat } from "../TimePicker/types";

import "./styles.scss";

export function BusinessHours(props: BusinessHourProps) {
  const { defaultValue, onClick, ctaStyle } = props;
  const defaultHoursData = () => {
    let daysData: BusinessHoursDays = getEmptyDays();
    if (defaultValue?.days) {
      Object.values(defaultValue?.days).every((el) => el === undefined)
        ? (daysData = getEmptyDays())
        : (daysData = defaultValue?.days);
    }
    return daysData;
  };

  const { t } = useTranslation();

  const modalContext = React.useContext(ModalContext);

  const [timeFormat, setTimeFormat] = React.useState<TimeFormat>(defaultValue?.format || "AM/PM");
  const [days, setDays] = React.useState<BusinessHoursDays>(defaultHoursData());

  const renderDayTimes = (day: string) => {
    if ((days[day] as BusinessHoursDay)?.times?.length === 0) {
      return renderTime(0, day);
    }

    return (days[day] as BusinessHoursDay)?.times?.map((time, idx) => renderTime(idx, day, time));
  };

  const renderTime = (idx: number, day: string, defaultValue?: BusinessHourTime) => {
    return (
      <div
        key={`${day}-${idx}`}
        className="opening-hours__days__day__times__time">
        <BusinessHourInput
          defaultValue={formatToBusinessHoursInput(defaultValue?.start, timeFormat)}
          placeholder={timeFormat === "AM/PM" ? "08:00 am" : "08:00"}
          isEnabled={(days[day] as BusinessHoursDay)?.enabled}
          onClick={() => onSelectTime(day, "start", idx)}
        />
        <BusinessHourInput
          defaultValue={formatToBusinessHoursInput(defaultValue?.end, timeFormat)}
          placeholder={timeFormat === "AM/PM" ? "08:00 am" : "08:00"}
          isEnabled={(days[day] as BusinessHoursDay)?.enabled}
          onClick={() => onSelectTime(day, "end", idx)}
        />
        <Button
          type="button"
          tertiary
          square
          icon={idx === 0 ? <HiOutlinePlus /> : <FiTrash2 />}
          onClick={() => onAddRemoveTime(idx, day)}
          className={`${!(days[day] as BusinessHoursDay)?.enabled ? "add-remove--disabled" : ""}`}
          rounded={!!ctaStyle}
        />
      </div>
    );
  };

  const onSelectTime = (day: string, timeSlot: "start" | "end", timeIndex: number) => {
    const time = days[day]?.times[timeIndex][timeSlot];
    const formattedTimerTime = formatToTimerTime(time || "08:00", timeFormat);

    const { hour, minutes, type } = formattedTimerTime;

    modalContext.handleModal({
      content: getTimerModal({
        onConfirmButton: (time) => {
          const formattedTime = formatToGenericTime(time);
          const newDays = cloneDeep(days);
          if (newDays[day]?.times) {
            newDays[day].times[timeIndex] = {
              ...newDays[day].times[timeIndex],
              [timeSlot]: formattedTime,
            };
          }
          setDays(newDays);
          onClick && onClick({ format: timeFormat, days: newDays });
          modalContext.handleModal();
        },
        onCancelButton: () => modalContext.handleModal(),
        data: {
          timeFormat,
          hour,
          minutes,
          type,
        },
      }),
    });
  };

  const onAddRemoveTime = (idx: number, day: string) => {
    if (idx === 0) {
      const newDays = clone(days);
      (newDays[day] as BusinessHoursDay)?.times.push({ start: "", end: "" });
      onClick && onClick({ format: timeFormat, days: newDays });
      setDays(newDays);
    } else {
      const newDays = clone(days);
      newDays[day] = {
        ...newDays[day],
        times: filter(newDays[day]?.times, (_, timeIdx) => idx !== Number(timeIdx)),
      };
      onClick && onClick({ format: timeFormat, days: newDays });
      setDays(newDays);
    }
  };

  const onChangeEnabled = (day: string, checked: boolean) => {
    const newDays = clone(days);
    newDays[day] = {
      ...newDays[day],
      enabled: checked,
    };
    onClick && onClick({ format: timeFormat, days: newDays });
    setDays(newDays);
  };

  React.useEffect(() => {
    onClick && onClick({ format: timeFormat, days: days });
  }, [timeFormat]);

  return (
    <div className="opening-hours">
      <div className="opening-hours__format">
        <Button
          type="button"
          label="AM/PM"
          tertiary
          onClick={() => setTimeFormat("AM/PM")}
          className={`${timeFormat === "24" ? "hours__format--disabled" : ""}`}
          rounded={!!ctaStyle}
        />
        <Button
          type="button"
          label="24 hours"
          tertiary
          onClick={() => setTimeFormat("24")}
          className={`${timeFormat === "AM/PM" ? "hours__format--disabled" : ""}`}
          rounded={!!ctaStyle}
        />
      </div>
      <div className="opening-hours__days">
        {Object.keys(days).map((day) => {
          const dayCapitalized = startCase(day);

          return (
            <React.Fragment key={day}>
              <div className="opening-hours__days__day">
                <Checkbox
                  id={t(`_businessTime${dayCapitalized}`).toLowerCase()}
                  label={t(`_businessTime${dayCapitalized}`)}
                  defaultValue={days[day]?.enabled}
                  className="opening-hours__days__day__checkbox"
                  onChange={(e) => onChangeEnabled(day, e.target.checked)}
                />
                <div className="opening-hours__days__day__times">
                  {useMemo(() => renderDayTimes(day), [timeFormat, days])}
                </div>
              </div>
              {day !== "sunday" && (
                <Divider
                  spacing={4}
                  className="opening-hours__days__divider"
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
