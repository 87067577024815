import client from "../client";
import { getQRReferenceConfig } from "./getQRReferenceConfig";

const getQRReference = (data?: any) => {
  return client({ ...getQRReferenceConfig(data.qrReference) });
};

export default {
  getQRReference,
};
