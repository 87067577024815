"use client";

import React, { useEffect, useState } from "react";
import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import { QREditorContext } from "@/contexts/QREditorContext";
import { FrameDesignContent } from "../FrameDesignContent/FrameDesignContent";
import { FramesTabsProps } from "./types";
import "./styles.scss";
import { getFrameSectionClass, handleFrameSelectedSection } from "@/utils/framesHelper";

export const FramesTabs = ({ tabsData }: FramesTabsProps) => {
  const [indexSelected, setIndexSelected] = React.useState(0);
  const { qrData } = React.useContext<QREditorContextProps<any>>(QREditorContext);

  useEffect(() => {
    /*prevent that if "all" is selected, it will not change the selected tab*/
    if (Number(indexSelected) === tabsData.length) return;

    const isFrameSelected = handleFrameSelectedSection(tabsData, qrData, "frame-div-toggle");
    isFrameSelected && setIndexSelected(isFrameSelected.id - 1);
  }, [qrData.qrStyle.frameStyle]);

  const handleClick = (id) => {
    getFrameSectionClass("frame-div-toggle", id);
    setIndexSelected(id);

    const items = Array.from(
      document.getElementsByClassName("frames-tab") as HTMLCollectionOf<HTMLElement>
    );
    for (let i = 0; i < items.length; i++) {
      const div = items[i];
      div.id === id ? div.classList.add("active") : div.classList.remove("active");
    }
  };

  return (
    <div id="frames-tabs">
      <div className="frames-tabs-container">
        {tabsData.map((tab, i) => (
          <div
            onClick={() => handleClick(tab.id)}
            id={tab.id.toString()}
            className={`frames-tab ${i === indexSelected ? "active" : ""} ${tab.style ? "frames-tab--rounded" : ""}`}>
            {tab.label} {tab.subLabel && <span className="tab-subLabel">{tab.subLabel}</span>}
          </div>
        ))}
      </div>
      <FrameDesignContent
        content={tabsData}
        className={"frame-div-toggle"}
      />
    </div>
  );
};
