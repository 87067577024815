import React, { useState } from "react";

import { ConnectForm } from "@/utils/formHelper";
import { Tooltip } from "@/components/Tooltip";
import { PickerOption, PickerProps } from "@/components/Picker/types";
import PickerItem from "@/components/Picker/PickerItem";

import "./styles.scss";
import { QREditorContext } from "@/contexts/QREditorContext";
import { QREditorContextProps } from "@/contexts/QREditorContext/types";

export function FramePicker({
  title,
  options,
  multiple,
  id,
  dataQA,
  defaultValue,
  className,
  selector,
  onClick,
  defaultSelected,
}: PickerProps) {
  let defaultOption = defaultSelected || defaultValue;
  const [selectedOption, setSelectedOption] = useState<string | string[]>(defaultOption);

  const { qrData } = React.useContext<QREditorContextProps<any>>(QREditorContext);

  React.useEffect(() => {
    if (onClick) onClick(selectedOption);
  }, [selectedOption]);

  React.useEffect(() => {
    if (id === "frameStylePicker") {
      setSelectedOption(qrData.qrStyle.frameStyle);
      onClick(qrData.qrStyle.frameStyle);
    }
  }, [qrData.qrStyle.frameStyle]);

  return (
    <ConnectForm>
      {title && <p>{title}</p>}
      <ul
        data-testid="FramePicker"
        data-qa={dataQA}
        className={`FramePicker${className ? " " + className : ""}`}
        id={id}>
        {options.map((option: string | PickerOption, i: number) => {
          const formattedOption = typeof option === "string" ? option : option.item;
          if (typeof option !== "string" && option.tooltip) {
            return (
              <React.Fragment key={i}>
                <div>
                  <Tooltip
                    text={option.tooltip?.text}
                    position={option.tooltip?.position}
                    id={`${i}`}>
                    <PickerItem
                      option={formattedOption}
                      label={option?.label}
                      id={i}
                      multiple={multiple}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      selector={selector}
                    />
                  </Tooltip>
                </div>
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={i}>
              <PickerItem
                option={formattedOption}
                label={typeof option !== "string" && option?.label}
                id={i}
                multiple={multiple}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                selector={selector}
              />
            </React.Fragment>
          );
        })}
      </ul>
    </ConnectForm>
  );
}
