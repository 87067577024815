import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";

import { ListActionButtonProps } from "./types";

export const ListActionButton = ({ actionButton, onActionButtonClick, disabled }: ListActionButtonProps) => {
  const { t } = useTranslation("feedback");

  const button = actionButton || (
    <Button 
      ghost 
      type="button"
      dataTestId="action-button-default"
      label={t("_feedbackCategoryAddCategoryButton")}
      onClick={onActionButtonClick} 
      disabled={disabled}
    />
  );

  return (
    <div>
      {button}
    </div>
  );
}
