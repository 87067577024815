import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { FiRefreshCw } from "react-icons/fi";

import { Input } from "../Input";
import { Button } from "../Button";
import { QREditorContext } from "@/contexts/QREditorContext";
import { QRProps } from "@/contexts/QREditorContext/types";
import { checkValidHexColor, ConnectForm } from "@/utils/formHelper";

import { ColorGroupControlledProps } from "./types";

import { useFormContext } from "react-hook-form";
import "./styles.scss";

export const ColorGroupControlled = (props: ColorGroupControlledProps) => {
  const {
    primaryColor: {
      id: primaryColorId,
      label: primaryColorLabel,
      color: primaryColor,
      className: primaryColorClassName,
    },
    secondaryColor: {
      id: secondaryColorId,
      label: secondaryColorLabel,
      color: secondaryColor,
      className: secondaryColorClassName,
    },
    className,
    onChange,
  } = props;

  const { clearErrors, setError, setValue, trigger } = useFormContext();

  const [errors, setErrors] = useState(undefined);

  const qrEditorContext = useContext(QREditorContext);

  const onSwitchColors = () => {
    if (!qrEditorContext || !isEmpty(errors)) return;

    const qrData = qrEditorContext.qrData as QRProps<any>;
    const qrStyle = qrData.qrStyle;

    const primaryColor = qrData.qrStyle[primaryColorId];
    const secondaryColor = qrData.qrStyle[secondaryColorId];

    if (primaryColor && secondaryColor) {
      qrEditorContext.setQrData({
        ...qrData,
        qrStyle: {
          ...qrStyle,
          [primaryColorId]: secondaryColor,
          [secondaryColorId]: primaryColor,
        },
      });
    }
    onUpdate();
  };

  const onUpdate = () => {
    const primaryColorInput = document.getElementById(primaryColorId) as HTMLInputElement;
    const primaryColorPicker = document.getElementById(
      `color-picker-${primaryColorId}`
    ) as HTMLDivElement;
    const secondaryColorInput = document.getElementById(secondaryColorId) as HTMLInputElement;
    const secondaryColorPickerPicker = document.getElementById(
      `color-picker-${secondaryColorId}`
    ) as HTMLInputElement;

    primaryColorInput.value = qrEditorContext?.qrData?.qrStyle?.[primaryColorId];

    secondaryColorInput.value = qrEditorContext?.qrData?.qrStyle?.[secondaryColorId];

    primaryColorPicker.setAttribute(
      "style",
      `background-color: ${qrEditorContext?.qrData?.qrStyle?.[primaryColorId]};`
    );
    secondaryColorPickerPicker.setAttribute(
      "style",
      `background-color: ${qrEditorContext?.qrData?.qrStyle?.[secondaryColorId]};`
    );
  };

  const [firstColor, setFirstColor] = useState(
    qrEditorContext?.qrData?.qrStyle?.[primaryColorId] || primaryColor
  );

  const [secondColor, setSecondColor] = useState(
    qrEditorContext?.qrData?.qrStyle?.[secondaryColorId] || secondaryColor
  );

  const handleFirstColorChange = (e: any) => {
    onChange && onChange(e, "primary");
    setFirstColor(e);
  };

  const handleSecondColorChange = (e: any) => {
    onChange && onChange(e, "secondary");
    setSecondColor(e);
  };

  useEffect(() => {
    onUpdate();
  }, [firstColor, secondColor, qrEditorContext?.qrData?.qrStyle]);

  useEffect(() => {
    const updateFieldAndCleanErrors = (field: string) => {
      if (
        qrEditorContext?.qrData?.qrStyle?.[field] &&
        checkValidHexColor(qrEditorContext?.qrData?.qrStyle?.[field])
      ) {
        setValue(field, qrEditorContext?.qrData?.qrStyle?.[field]);
        clearErrors(field);
        setError(field, undefined);
        setErrors({ ...errors, [field]: undefined });
        trigger(field, { shouldFocus: true });
      }
    };

    updateFieldAndCleanErrors(primaryColorId);
    updateFieldAndCleanErrors(secondaryColorId);
  }, [qrEditorContext?.qrData?.qrStyle]);

  return (
    <ConnectForm onMethods={(data) => setErrors(data?.formState?.errors || undefined)}>
      <div
        suppressHydrationWarning
        data-testid="color-group"
        className={`color-group ${className || ""}`}>
        <Input
          id={primaryColorId}
          label={primaryColorLabel}
          type="text"
          colorPicker={primaryColor}
          colorPickerWithDebounce={true}
          defaultValue={qrEditorContext.qrData?.qrStyle?.[primaryColorId] || primaryColor}
          data-testid="color-group-primary"
          className={`${primaryColorClassName}`}
          placeholder={primaryColor}
          name={primaryColorId}
          error={errors && errors[primaryColorId]?.message}
          errorMessage={errors && errors[primaryColorId]?.message}
          onChange={handleFirstColorChange}
        />
        <Button
          ghost
          dataTestId="color-group-button"
          type="button"
          icon={<FiRefreshCw />}
          onClick={() => onSwitchColors()}
        />
        <Input
          id={secondaryColorId}
          label={secondaryColorLabel}
          type="text"
          colorPicker={secondaryColor}
          colorPickerWithDebounce={true}
          defaultValue={qrEditorContext.qrData?.qrStyle?.[secondaryColorId] || secondaryColor}
          data-testid="color-group-secondary"
          className={`${secondaryColorClassName}`}
          placeholder={secondaryColor}
          name={secondaryColorId}
          error={errors && errors[secondaryColorId]?.message}
          errorMessage={errors && errors[secondaryColorId]?.message}
          onChange={handleSecondColorChange}
        />
      </div>
    </ConnectForm>
  );
};
