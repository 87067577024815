"use client";

import React, { useEffect, useState } from "react";
import { TemplatesTabsProps } from "./types";
import { TemplatesContent } from "../TemplatesContent/TemplatesContent";
import { getTemplatesService } from "@/interface/api/getTemplates";
import { TemplateData, TemplatesData } from "@/data/types/templates";
import { useApi } from "@/hooks/api/useApi";
import { useToast } from "@/hooks/ui/useToast";
import "./styles.scss";

export const TemplatesTabs = ({ tabsData }: TemplatesTabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userTemplates, setUserTemplates] = useState([] as TemplateData[]);
  const { request: requestTemplates } = useApi(getTemplatesService);
  const { toast } = useToast();

  useEffect(() => {
    requestTemplates()
      .then((response) => {
        const { templates } = response.data as TemplatesData;
        setUserTemplates(templates);
      })
      .catch(() => {
        toast({ message: "genericError", type: "error" });
      });
  }, []);

  const handleClick = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <>
      <div className="templates-tabs-container">
        {tabsData.map((tab, index) => {
          if (tab.label === "Templates" && userTemplates?.length === 0) {
            return null;
          }
          return (
            <div
              key={index}
              onClick={() => handleClick(index)}
              id={tab.id.toString()}
              className={`templates-tab ${index === selectedIndex ? "active" : ""} ${tab.style ? "templates-tab--rounded" : ""} `}>
              {tab.label}
            </div>
          );
        })}
      </div>
      <TemplatesContent
        selectedIndex={selectedIndex}
        userTemplates={userTemplates}
      />
    </>
  );
};
